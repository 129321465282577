/* Footer.css */

.siteFooter {
    background-color: #343a40;
    color: white;
    text-align: center;
    padding: 20px 10px;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .footerContent {
    margin: auto;
    padding: 20px 0;
  }
  
  .socialLinks a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
  }
  
  .socialLinks a:hover {
    text-decoration: underline;
  }
  