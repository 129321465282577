/* App.css - Global styles for a modern and minimalistic look */

/* Resetting margins and paddings for consistency */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Setting up the body with a neutral background and comfortable reading font */
body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: var(--background-color);
  color: #212529;
  line-height: 1.6;
}

/* Common container for centering content and setting max width */
.container {
  max-width: 1200px;
  margin: auto;
  padding: 0 15px;
}

/* Main header styles for a consistent top area */
.header {
  color: var(--primary-color);
  color: #ffffff;
  padding: 20px 0;
  text-align: center;
}

/* Styling for navigation links */
.nav-links {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 1rem 0;
}

.nav-links a {
  color: white;
  text-decoration: none;
  padding: 0 15px;
}

.nav-links a:hover {
  text-decoration: underline;
}

/* Basic button styling */
.button {
  color: var(--primary-color);
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.button:hover {
  color: var(--secondary-color);
}

/* Utility classes for margin and padding */
.mt-20 {
  margin-top: 20px;
}

.pt-20 {
  padding-top: 20px;
}

/* Styling for footer */
.footer {
  background-color: #343a40;
  color: #ffffff;
  text-align: center;
  padding: 20px 0;
  margin-top: 20px;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .nav-links {
    flex-direction: column;
  }

  .nav-links a {
    margin: 5px 0;
  }
}
