/* HomePage.css */

.homePage {
    max-width: 1200px;
    margin: auto;
    padding: 1rem;
}
  
/* Hero Section */
.hero-section {
    text-align: center;
    padding: 2rem 0;
}

.hero-section h1 {
    margin-bottom: 1rem;
    color: var(--primary-color);
}

.hero-section p {
    margin-bottom: 2rem;
    color: var(--text-color);
}

.hero-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

  .servicesList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
  }
  
  .service {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .service:hover {
    transform: scale(1.05);
    background-color: #e7efff;
  }
  
  .service h2 {
    margin-bottom: 1rem;
    color: var(--secondary-color);
  }
  
  .service p {
    color: #333;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .servicesList {
      grid-template-columns: 1fr;
    }
  }