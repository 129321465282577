/* ContactForm.css */

.contactFormContainer {
  max-width: 600px;
  margin: auto;
  padding: 2rem;
  text-align: center;
}

.contactForm {
  margin-top: 2rem;
}

.formGroup {
  margin-bottom: 1rem;
  text-align: left;
}

label {
  display: block;
  margin-bottom: .5rem;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: .5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submitButton {
  color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.submitButton:hover {
  color: var(--secondary-color);
}
