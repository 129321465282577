/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: var(--background-color);
  color: var(--text-color);
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Global Styles */
a {
  text-decoration: none;
  color: var(--primary-color);
}

a:hover {
  color: var(--secondary-color);
}

/* Utility Classes */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Example of CSS Variables */
:root {
  --primary-color: #007bff;
  --secondary-color: #0056b3;
  --background-color: #f5f5f5;
  --text-color: #333;
  --font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
