/* src/resources/css/NavBar.css */
.navbar {
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
  
  .nav-link {
    color: white;
    text-decoration: none;
    margin: 0 15px;
    font-weight: bold;
  }
  
  .nav-link:hover {
    color: var(--primary-color);
    background-color: #ffffff;
  }
  